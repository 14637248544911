import axios from 'axios';

export async function getFile(fileId) {
  return axios({
    method: 'GET',
    url: process.env.NEXT_PUBLIC_API_URL + 'files/' + fileId + '/',
    withCredentials: true,
  }).then((response) => {
    return response.data;
  });
}

export async function downloadFile(file, filename) {
  return axios.get(file.file, {responseType: 'blob'}).then((response) => {
    const blob = new Blob([response.data]);
    blob.name = file.original_file_name;
    if (filename) {
      blob.name = filename;
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = blob.name;
    link.click();
  });
}

export async function uploadFile(file, validations, filename) {
  const {isValid, error} = validateFile(file, validations);
  if (!isValid) {
    return Promise.reject({errorMsg: error});
  }

  let newFilename = filename ?? file.name ?? 'untitled';
  var mime = require('mime-types');
  if (mime.lookup(newFilename) === false) {
    newFilename = newFilename + '.' + mime.extension(file.type);
  }
  return sendFileMetadataRequest('files/upload/direct/start', {
    file_name: newFilename,
    file_type: file.type,
  })
    .then(async (response) => {
      return await directUploadDo({data: response.data, file});
    })
    .then(async (id) => {
      return await sendFileMetadataRequest('files/upload/direct/finish', {
        file_id: id,
      });
    });
}

function sendFileMetadataRequest(url, data = {}) {
  let fetchData = {
    method: 'POST',
    withCredentials: true,
    url: process.env.NEXT_PUBLIC_API_URL + url + '/',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return axios(fetchData);
}

const directUploadDo = async ({data, file}) => {
  const postData = new FormData();

  for (const key in data?.fields) {
    postData.append(key, data.fields[key]);
  }

  postData.append('file', file);

  let postParams = {
    'Content-Type': 'multipart/form-data',
    withCredentials: true,
  };

  return axios.post(data.url, postData, postParams).then(() => data.id);
};

export function validateFile(file, validations) {
  if (!validations) return {isValid: true, error: null};

  if (validations.maxSize && file.size > validations.maxSize)
    return {
      isValid: false,
      error: 'O arquivo pode ter no máximo ' + formatBytes(validations.maxSize),
    };

  if (validations.type && !Array.isArray(validations.type))
    validations.type = [validations.type];
  if (
    validations.type &&
    validations.type.filter((validation) =>
      file.type.match('^' + validation.replace('*', '.*') + '$')
    ).length == 0
  )
    return {
      isValid: false,
      error: 'O arquivo deve ser do tipo ' + validations.type.join(' ou '),
    };

  return {isValid: true, error: null};
}

//Retirado de: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
function formatBytes(bytes) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
}
